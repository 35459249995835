import Table, { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { IVendorPaymentDetails } from '../types/vendor.interfaces';
import dayjs from 'dayjs';
import FormHeaderTitle from '../../Invoice(Visa)/Components/FormHeaderTitle';
import { useGetVendorPaymentDetailsQuery } from '../api/endpoints/vendorPaymentEndpoints';

type Props = {
  id: string | undefined;
};
const VendorPaymentViewDetails = (props: Props) => {
  const id = props.id;
  const [details, setDetails] = useState<IVendorPaymentDetails[]>();

  const fetchData = async () => {
    if (id) {
      try {
        const { data } = await useGetVendorPaymentDetailsQuery(Number(id)); // don't remove await

        if (data?.data) {
          setDetails(data.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  fetchData();

  const vendorPaymentCostColumn: ColumnsType<IVendorPaymentDetails> = [
    { title: 'SL', render: (_, data, index) => <> {index + 1} </> },
    {
      title: 'Payment Date',
      key: 'payment_date',
      dataIndex: 'payment_date',
      render: (data) => {
        return data ? dayjs(data).format('DD-MM-YYYY') : '';
      },
    },
    {
      title: 'Vendor Name',
      key: 'vendor_name',
      dataIndex: 'vendor_name',
    },
    {
      title: 'Payment Amount',
      key: 'payment_amount',
      dataIndex: 'payment_amount',
    },
    {
      title: 'Invoice No',
      key: 'invoice_no',
      dataIndex: 'invoice_no',
    },
    {
      title: 'Invoice Amount',
      key: 'invoice_net_total',
      dataIndex: 'invoice_net_total',
    },
    {
      title: 'Sales Date',
      key: 'invoice_sales_date',
      dataIndex: 'invoice_sales_date',
      render: (data) => {
        return data ? dayjs(data).format('DD-MM-YYYY') : '';
      },
    },
  ];
  return (
    <div
      className='my-20'
      style={{ backgroundColor: '#FFFFFF', padding: '20px', minWidth: '100vh' }}
    >
      <FormHeaderTitle title='PAYMENT DETAILS' />
      <Table
        size='small'
        bordered
        rowKey={(e) => e.payment_date}
        className='invoiceBillingTable'
        rowClassName={'invoiceBillingTd'}
        dataSource={details}
        columns={vendorPaymentCostColumn}
        pagination={false}
        style={{ marginBottom: '20px' }}
      />
    </div>
  );
};

export default VendorPaymentViewDetails;
