import { Tabs } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router';
import { perProps } from '../../../../../common/types/commonTypes';
import BreadCrumb from '../../../../../components/common/breadCrumb/BreadCrumb';
import Agent_Incentive_Income from '../components/Agent_Incentive_Income';
import Client_Incentive_Income from '../components/Client_Incentive_Income';
import Vendor_Incentive_Income from '../components/Vendor_Incentive_Income';

const Incentive_Income_main = ({ permission }: perProps) => {
  const { activeTab } = useParams();

  const [tabChanged, setTabChange] = useState<string>('');

  return (
    <>
      <BreadCrumb arrOfOption={['Incentive Income']} reloaderSize='small' />
      <Tabs
        type='line'
        onChange={setTabChange}
        items={[
          {
            label: 'Vendor Incentive Income',
            key: '1',

            children: (
              <Vendor_Incentive_Income
                permission={permission}
                tabChanged={tabChanged}
              />
            ),
          },
          {
            label: 'Clients & Combined Incentive Income',
            key: '2',

            children: (
              <Client_Incentive_Income
                permission={permission}
                tabChanged={tabChanged}
              />
            ),
          },

          {
            label: 'Agent Incentive Income',
            key: '3',

            children: (
              <Agent_Incentive_Income
                permission={permission}
                tabChanged={tabChanged}
              />
            ),
          },
        ]}
      ></Tabs>
    </>
  );
};

export default Incentive_Income_main;
