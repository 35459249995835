import { ConfigProvider, Row, message } from 'antd';
import 'antd/dist/reset.css';
import { BroadcastChannel } from 'broadcast-channel';
import { useEffect, useState } from 'react';
import { getSessionId } from '.';
import { api, useGetInitialTokenMutation } from './app/baseQuery';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { CustomRoutes } from './app/routes/Routes';
import Maintenance from './auth/pages/Maintenance';
import { setData } from './auth/states/configSlice';
import { logout } from './auth/states/userSlice';
import useEffectOnce from './common/hooks/useEffectOnce';
import { globalTheme } from './common/slices/themeSlice';
import { rolePermissionType } from './common/types/commonTypes';
import LoadingIndicator from './components/common/spinner/LoadingIndicator';
import { useLazyGetAppConfigQuery } from './modules/Configuration/appConfig/endpoint/appConfigEndpoint';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(timeZone);

function App() {
  const dispatch = useAppDispatch();
  const theme = useAppSelector(globalTheme);
  const [triggered, setTriggered] = useState(false);
  const [init, setInit] = useState(true);
  const [trigger] = useGetInitialTokenMutation();
  const sessionId = getSessionId();
  const logoutChannel = new BroadcastChannel('logout');
  const [getData, { data, isSuccess }] = useLazyGetAppConfigQuery();
  const user = useAppSelector((state) => state.user);

  const permission: rolePermissionType | undefined =
    user?.role_permissions &&
    JSON.parse(user?.role_permissions)[user?.role_name];

  // MAINTENANCE OR UNDER CONTRACTIONS
  const isUnderContractions = false;
  if (isUnderContractions) {
    return <Maintenance />;
  }

  useEffect(() => {
    if (user) {
      getData();
    }
  }, [user]);

  const appConfigData = data?.data;

  useEffect(() => {
    if (isSuccess && appConfigData) {
      dispatch(setData(appConfigData));
    }
  }, [isSuccess, appConfigData]);

  logoutChannel.onmessage = (msg) => {
    const message = msg;

    if (message === 'Logout') {
      dispatch(api.util.resetApiState());
      dispatch(logout());
    }
  };

  if (!triggered) {
    if (sessionId) {
      trigger(sessionId)
        .unwrap()
        .then(() => {
          setInit(false);
        })
        .catch(() => {
          setInit(false);
        });
    } else {
      setInit(false);
    }
    setTriggered(true);
  }

  useEffectOnce(() => {
    window.addEventListener('offline', function (e) {
      message.error('Connection Offline');
    });

    window.addEventListener('online', function (e) {
      message.success('Connection Online');
    });
  });

  if (init) {
    return (
      <Row
        justify='center'
        align='middle'
        style={{ position: 'fixed', top: '50%', left: '50%' }}
      >
        {<LoadingIndicator />}
      </Row>
    );
  }

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.theme,
        token: {
          colorPrimary: '#18b4e9',
        },
        components: {
          Select: {
            controlItemBgHover: '#56d5ff',
          },
          Pagination: { colorText: '#18b4e9' },
        },
      }}
    >
      <CustomRoutes user={user} key={12} permission={permission} />
    </ConfigProvider>
  );
}

export default App;
