import { Col, Image, Row, Typography } from 'antd';
import { useAppSelector } from '../../../app/hooks';
import NumToWord from '../../../components/number_to_words/NumToWord';
import lib from '../../Client/Client/utils/util_ts/lib';
import {
  IAirTicketRefundVendorDetails,
  IAirtTicketRefundSingleDataType,
} from '../RefundTypes/RefundTypes';

type Props = {
  refund?: IAirtTicketRefundSingleDataType;
};
type VendorRefundProps = {
  refund?: IAirTicketRefundVendorDetails;
  voucherNumber?: string;
  refundDate: string;
};

export const ViewRefundHeaderVendor = ({
  refund,
  refundDate,
  voucherNumber,
}: VendorRefundProps) => {
  return (
    <Row justify={'space-between'} align={'middle'}>
      <Col>
        <Image
          style={{ marginBottom: '1rem' }}
          width={100}
          src='https://basis.org.bd/public/images/logo/54e198aa3eae15c215688af3bb13de5f16022022025439.png'
          alt='M360 ICT'
        />
        <Typography.Paragraph>
          <Typography.Text strong>M360 ICT</Typography.Text>
          <br />
          <Typography.Text strong>
            {` Refund To : 
            ${refund?.vendor_name}`}
          </Typography.Text>
          <br />
          <Typography.Text strong>
            {refund?.vendor_mobile &&
              `Mobile No. : ${lib.parseMobile(refund.vendor_mobile)}`}
          </Typography.Text>
        </Typography.Paragraph>
      </Col>

      <Col>
        <Typography.Title level={3}>REFUND VOUCHER</Typography.Title>
        <Typography>Refund Voucher No : {voucherNumber}</Typography>
        <Typography>Refund Date : {refundDate}</Typography>
      </Col>
    </Row>
  );
};
const ViewRefundHeader = ({ refund }: Props) => {
  const orgInfo = useAppSelector((state) => state.user?.organization_info);

  return (
    <>
      <Row
        style={{
          fontFamily: "'Source Sans Pro', sans-serif",
          borderBottom: '2px solid #F9F5F6',
        }}
        justify={'space-between'}
        align='middle'
      >
        <Col style={{ display: 'flex', alignItems: 'center' }}>
          <img
            height={'90px'}
            src={orgInfo?.org_logo || '/m360Ict_Logo.png'}
            alt={orgInfo?.org_name || 'M360-ICT'}
            style={{
              padding: '10px 20px 10px 0',
              borderRadius: 5,
              margin: '15px 0',
            }}
          />
        </Col>

        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div>
            <Typography.Title level={4}>
              {orgInfo?.org_name || 'M360 ICT'}
            </Typography.Title>

            <Typography.Text
              style={{
                display: 'block',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              {orgInfo?.org_address1}
            </Typography.Text>

            <Typography.Text
              style={{
                display: 'block',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
            >
              {orgInfo?.org_address2}
            </Typography.Text>

            <Typography.Text
              style={{
                display: 'block',
                fontSize: '12px',
                fontFamily: "'Source Sans Pro', sans-serif",
              }}
              className=''
            >
              Mobile : {orgInfo?.org_mobile}
            </Typography.Text>
          </div>
        </Col>
      </Row>
    </>
  );
};
export const ViewRefundFooter = ({
  amount,
  note,
}: {
  amount?: any;
  note: string | undefined;
}) => {
  return (
    <>
      <div
        style={{ margin: '30px 0', display: 'flex', flexDirection: 'column' }}
      >
        <Typography.Text strong>
          <NumToWord number={Number(amount || 0)} />
        </Typography.Text>
        <Typography.Text strong>Note : {note} </Typography.Text>
      </div>
      <Row style={{ marginTop: '15%' }} justify='space-between'>
        <Col>
          <div
            style={{
              background: 'black',
              margin: '0px',
              width: '135px',
              height: '1px',
            }}
          />
          <Typography.Text style={{ fontSize: '15px' }}>
            Customer Signature
          </Typography.Text>
        </Col>

        <Col>
          <div
            style={{
              background: 'black',
              margin: '0px',
              width: '140px',
              height: '1px',
            }}
          />
          <Typography.Text style={{ fontSize: '15px' }}>
            Authorized Signature
          </Typography.Text>
        </Col>
      </Row>
    </>
  );
};

export default ViewRefundHeader;
