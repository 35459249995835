import {
  ArrowLeftOutlined,
  PrinterOutlined
} from '@ant-design/icons';
import {
  Button,
  Col,
  ConfigProvider,
  Row,
  Space,
  Table,
  Typography,
  theme,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import { useAppSelector } from '../../../app/hooks';
import {
  InvoiceHeader,
  TitleCenter,
} from '../../../common/Invoice/InvoiceHeader';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import { PhoneParser } from '../../../helpers/PhoneFormatter';
import {
  useGetBillInfoQuery,
  useGetSingleQuotationQuery,
} from '../Api/Endpoints/QuotationEndpoints';
import { IQuotationBillInfo } from '../QuotationTypes/QuotationTypes';
import { BillingInfoUtils } from '../Utils/BillingInfoUtils';
import QuotationSubTotal from './QuotationSubTotal';

function NewViewQuotationDetails() {
  const { id } = useParams();

  const [date, setDate] = useState<string>();

  const { data: singleQuotation, isFetching: fetchIsLoading } =
    useGetSingleQuotationQuery(Number(id));
  const { Text } = Typography;
  const printTime = dayjs().format('MMMM Do YYYY, h:mm:ss a');
  const { data: billInfo } = useGetBillInfoQuery(Number(id), {
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const dataToReturn: IQuotationBillInfo[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          dataToReturn.push({
            ...element,
            key: i + 1,
          });
        }
      }
      return { ...cache, data: dataToReturn };
    },
  });

  useEffect(() => {
    if (singleQuotation?.data) {
      setDate(
        dayjs(singleQuotation?.data.quotation_date).format('DD MMM YYYY')
      );
    }
  }, [singleQuotation?.data]);

  const user = useAppSelector((state) => state.user);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Quotation_${name}_${date}`,
  });

  const {
    subtotal: quotation_sub_total,
    quotation_net_total,
    quotation_discount_total,
  } = singleQuotation?.data || {};

  //   =============== style==============
  const invoiceInfoCard: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
  };
  const invoiceInfoCardOuter: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
  };
  const officeInfo = {
    name: 'M360 ICT',
    address1: '3RD FLOOR FLAT-F2(ROAD 07 HOUSE 74)',
    address2: 'BANANI, DHAKA 1213',
    modile: ' +88-01715248942',
  };

  const invoiceViewStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgba(211, 211, 211, 0.2)',
    minHeight: '100vh',
    padding: '20px 0',
  };
  const a4sizeStyle: React.CSSProperties = {
    minHeight: '11.5in',
    width: '8.27in',
    fontSize: '11px',
    background: '#fff',
    margin: '0px auto',
  };

  // ============ invoice header address
  return (
    <div>
      <BreadCrumb arrOfOption={['Quotation', 'View Quotation']} />

      <Space style={{ marginBottom: '1rem' }}>
        <Link to='/quotation'>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Quotation List
          </Button>
        </Link>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
      </Space>
      <ViewInvoiceStyle style={invoiceViewStyle}>
        <ConfigProvider
          theme={{
            algorithm: theme.defaultAlgorithm,
          }}
        >
          <div ref={componentRef}>
            <div style={{ ...a4sizeStyle, position: 'relative' }}>
              <InvoiceHeader />

              <TitleCenter title='quotation' />

              <Row
                justify={'space-between'}
                align='middle'
                className='mt-5 p-10'
              >
                <Col
                  style={{
                    color: '#fff',
                    fontFamily: "'Source Sans Pro', sans-serif",
                  }}
                >
                  {singleQuotation?.data?.client_name && (
                    <Typography.Text
                      style={{
                        display: 'block',
                        fontSize: '12px',
                        fontFamily: "'Source Sans Pro', sans-serif",
                      }}
                    >
                      <b>Name :</b> {singleQuotation?.data?.client_name}
                    </Typography.Text>
                  )}
                  {singleQuotation?.data?.client_address && (
                    <Typography.Text
                      style={{
                        display: 'block',
                        fontSize: '12px',
                        fontFamily: "'Source Sans Pro', sans-serif",
                      }}
                    >
                      <b>Address :</b> {singleQuotation?.data?.client_address}
                    </Typography.Text>
                  )}

                  {singleQuotation?.data?.client_mobile && (
                    <Typography.Text
                      style={{
                        display: 'block',
                        fontSize: '12px',
                        fontFamily: "'Source Sans Pro', sans-serif",
                      }}
                    >
                      <b>Mobile :</b>{' '}
                      {PhoneParser(singleQuotation?.data?.client_mobile)}
                    </Typography.Text>
                  )}
                  {singleQuotation?.data?.client_email && (
                    <Typography.Text
                      style={{
                        display: 'block',
                        fontSize: '12px',
                        fontFamily: "'Source Sans Pro', sans-serif",
                      }}
                    >
                      <b>Email :</b> {singleQuotation?.data?.client_email}
                    </Typography.Text>
                  )}
                  {singleQuotation?.data?.client_email && (
                    <Typography.Text
                      style={{
                        display: 'block',
                        fontSize: '12px',
                        fontFamily: "'Source Sans Pro', sans-serif",
                      }}
                    >
                      <b>Address :</b> {singleQuotation?.data?.client_address}
                    </Typography.Text>
                  )}
                </Col>

                <Col className='p-10'>
                  <Typography.Text
                    style={{
                      display: 'block',
                      fontSize: '12px',
                      fontFamily: "'Source Sans Pro', sans-serif",
                    }}
                  ></Typography.Text>
                  <Typography.Text
                    style={{
                      display: 'block',
                      fontSize: '12px',
                      fontFamily: "'Source Sans Pro', sans-serif",
                    }}
                  ></Typography.Text>
                  <Typography.Text
                    style={{
                      display: 'block',
                      fontSize: '12px',
                      fontFamily: "'Source Sans Pro', sans-serif",
                    }}
                  >
                    <b>Quotation Date :</b>{' '}
                    {singleQuotation?.data?.quotation_date
                      ? dayjs(singleQuotation?.data?.quotation_date).format(
                          'DD MMM YYYY'
                        )
                      : ''}
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      display: 'block',
                      fontSize: '12px',
                      fontFamily: "'Source Sans Pro', sans-serif",
                    }}
                  >
                    Sales By :{singleQuotation?.data?.user_full_name}
                  </Typography.Text>

                  <Typography.Text
                    style={{
                      display: 'block',
                      fontSize: '12px',
                      fontFamily: "'Source Sans Pro', sans-serif",
                    }}
                  >
                    Prepared By : {user?.user_full_name}
                  </Typography.Text>
                </Col>
              </Row>

              <div
                style={{
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  minHeight: '8in',
                  //   fontFamily: "'Source Sans Pro', sans-serif",
                }}
              >
                <div
                  style={{
                    marginBottom: '80px',
                    fontFamily: "'Source Sans Pro', sans-serif",
                  }}
                >
                  <Typography.Title
                    level={5}
                    style={{
                      marginBottom: '5px',
                      marginTop: '40px',
                    }}
                  >
                    BILLING INFO
                  </Typography.Title>
                  <Table
                    size='small'
                    className='invoiceBillingTable'
                    rowClassName={'invoiceBillingTd'}
                    bordered
                    columns={BillingInfoUtils}
                    dataSource={billInfo}
                    pagination={false}
                    loading={{
                      spinning: fetchIsLoading,
                      indicator: <LoadingIndicator />,
                    }}
                  />
                  <Row justify='space-between' align='middle'>
                    <Typography.Title
                      style={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                        color: '#434343',
                      }}
                    >
                      <span style={{ color: '#000', fontSize: '14px' }}>
                        Note:
                      </span>{' '}
                      {singleQuotation?.data?.quotation_note}
                    </Typography.Title>
                    <QuotationSubTotal
                      discount={quotation_discount_total}
                      net_total={quotation_net_total}
                      subtotal={quotation_sub_total}
                    />
                  </Row>
                </div>

                <div>
                  <Row
                    className='signature'
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '10px',
                    }}
                  >
                    <Typography.Text
                      style={{ borderTop: '1px solid black', color: 'black' }}
                    >
                      Customer Signature
                    </Typography.Text>
                    <Typography.Text
                      style={{ borderTop: '1px solid black', color: 'black' }}
                    >
                      Authority Signature
                    </Typography.Text>
                  </Row>

                  <Row justify='center'>
                    <Text style={{ textAlign: 'center' }}>{printTime}</Text>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </ConfigProvider>
      </ViewInvoiceStyle>
    </div>
  );
}

export default NewViewQuotationDetails;
const ViewInvoiceStyle = styled.div``;
