import { Col, FormListFieldData, Row } from 'antd';
import { FormInstance, useWatch } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  FormInputItem,
  NumberInput,
} from '../../../../components/common/FormItem/FormItems';
import {
  SelectCountry,
  SelectProduct,
} from '../../../../components/common/FormItem/SelectCustomFeilds';
import { ICostBillingInfo } from '../../../Invoice_Hajj_Pre_Reg/Types/InvoiceHajjiPre.Types';
import {
  IInvoiceTourAccom,
  IInvoiceTourBilling,
  IInvoiceTourFood,
  IInvoiceTourOtherTrans,
  IInvoiceTourTransports,
} from '../../Types/InvoiceTourTypes';
import { useGetAllAgentProfileQuery } from '../../../Client/Agents_Profile/api/endpoints/agentProfileEndPoints';
import { Fixed2 } from '../../../../common/utils/common.utils';

type Props = {
  field: FormListFieldData;
  form: FormInstance<any>;
  billInitialValues?: ICostBillingInfo[];
  isCostAdd?: boolean;
  tourBilling?: IInvoiceTourBilling[];
  index: number;
};

const TourBillingForm = ({
  field,
  form,
  isCostAdd,
  tourBilling,
  index,
}: Props) => {
  const { id } = useParams();
  // ==================== Calculations ======================
  const tourTransports: IInvoiceTourTransports[] = useWatch(
    ['tourTransports'],
    form
  );
  const tourFoods: IInvoiceTourFood[] = useWatch(['tourFoods'], form);
  const tourAccms: IInvoiceTourAccom[] = useWatch(['tourAccms'], form);
  const tourOtherTrans: IInvoiceTourOtherTrans[] = useWatch(
    ['tourOtherTrans'],
    form
  );
  // Guide
  const guide_cost_price: number | undefined = useWatch(
    ['guide_cost_price'],
    form
  );
  // Ticket
  const ticket_cost_price: number | undefined = useWatch(
    ['ticket_cost_price'],
    form
  );

  useEffect(() => {
    const tourTransportsCostTotal =
      tourTransports?.reduce(
        (a, item) => a + Number(item?.transport_cost_price || 0),
        0
      ) || 0;

    const tourFoodsCostTotal =
      tourFoods?.reduce(
        (a, item) => a + Number(item?.food_cost_price || 0),
        0
      ) || 0;

    const tourAccmsCostTotal =
      tourAccms?.reduce(
        (a, item) => a + Number(item?.accm_cost_price || 0),
        0
      ) || 0;

    const tourOtherTransCostTotal =
      tourOtherTrans?.reduce(
        (a, item) => a + Number(item?.other_trans_cost_price || 0),
        0
      ) || 0;

    form.setFieldValue(
      ['tourBilling', field.name, 'billing_cost_price'],
      (isCostAdd
        ? Fixed2([...(tourBilling || [])][0]?.billing_cost_price)
        : 0) +
        tourTransportsCostTotal +
        tourFoodsCostTotal +
        tourAccmsCostTotal +
        tourOtherTransCostTotal +
        Fixed2(guide_cost_price) +
        Fixed2(ticket_cost_price)
    );
  }, [
    tourTransports,
    tourFoods,
    tourAccms,
    tourOtherTrans,
    guide_cost_price,
    ticket_cost_price,
    id,
    tourBilling,
  ]);

  useEffect(() => {
    if (!id) {
      form.setFieldValue(
        ['tourBilling', field.name, 'billing_product_id'],
        120
      );
    }
  }, []);

  // GET/SET PROFIT
  const totalSales = useWatch(
    ['tourBilling', field.name, 'billing_total_sales'],
    form
  );
  const totalUnitCostTotal = useWatch(
    ['tourBilling', field.name, 'billing_cost_price'],
    form
  );

  useEffect(() => {
    form.setFieldValue(
      ['tourBilling', field.name, 'billing_profit'],
      Number(totalSales || 0) - Number(totalUnitCostTotal || 0)
    );
  }, [totalSales, totalUnitCostTotal]);

  //----------- calculate agent commission-----------------------------
  const { data } = useGetAllAgentProfileQuery();
  const invoice_agent_id: number | undefined = useWatch(
    'invoice_agent_id',
    form
  );
  const getAllAgentProfile = data?.data;
  const billing_profit = useWatch(['tourBilling'], form);
  const sumTotalProfit = billing_profit?.reduce(
    (acc: number, curr: any) => acc + Fixed2(curr?.billing_profit),
    0
  );
  useEffect(() => {
    if (invoice_agent_id) {
      const agentInfo = getAllAgentProfile?.find(
        (item) => item?.agent_id === invoice_agent_id
      );

      form.setFieldValue(
        ['invoice_agent_com_amount'],
        Fixed2(
          (Number(agentInfo?.agent_commission_rate || 0) / 100) *
            Number(sumTotalProfit || 0)
        )
      );
    }
  }, [invoice_agent_id, sumTotalProfit]);
  //-----------------------------
  const [disabled, setDisabled] = useState(false);
  const is_deleted: 0 | 1 = useWatch(
    ['tourBilling', index, 'is_deleted'],
    form
  );
  useEffect(() => {
    if (is_deleted === 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [is_deleted]);
  return (
    <Row
      style={
        is_deleted === 1
          ? {
              backgroundColor: '#FFC0CB',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              borderRadius: '5px',
            }
          : { display: 'flex', alignItems: 'center', width: '100%' }
      }
      gutter={[5, 5]}
    >
      <SelectProduct
        label='Product'
        name={[field.name, 'billing_product_id']}
        size={3}
        placeholder='Select Product'
        required
      />

      <SelectCountry
        label='Country'
        name={[index, 'billing_country_id']}
        size={3}
      />
      <FormInputItem
        label='Pax Name'
        name={[field.name, 'billing_pax_name']}
        size={3}
      />

      <NumberInput
        label='Total Room'
        name={[field.name, 'billing_numof_room']}
        size={3}
        min='0'
        maxChar={3}
      />
      <NumberInput
        label='Total Pax'
        name={[field.name, 'billing_total_pax']}
        size={3}
        min='0'
        maxChar={3}
      />

      <NumberInput
        label='Sales Price'
        name={[field.name, 'billing_total_sales']}
        size={3}
        readOnly={isCostAdd}
        required
        min='0'
        maxChar={14}
      />
      <NumberInput
        label='Cost Price'
        name={[field.name, 'billing_cost_price']}
        size={3}
        // readOnly
        rules={[{ required: true }]}
        min='0'
        maxChar={14}
      />
      <NumberInput
        label='Total Profit'
        name={[field.name, 'billing_profit']}
        size={3}
        readOnly
        min='0'
        maxChar={14}
      />
    </Row>
  );
};

export default TourBillingForm;
