import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Descriptions, Form, Row, Table, Typography } from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IPermission, crud } from '../../../../common/types/commonTypes';
import { pagination } from '../../../../common/utils/common.utils';
import {
  DateInput,
  FormButton,
} from '../../../../components/common/FormItem/FormItems';
import { SelectClients } from '../../../../components/common/FormItem/SelectCustomFeilds';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { useLazyGetSingleClientsQuery } from '../../../Client/Client/api/endpoints/clientEndpoints';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { useLazySearchTicketNoQuery } from '../../Api/Endpoints/AirTicketRefundEndpoints';
import AirticketPreviewForm from '../../Components/Airticket/AirticketPreviewForm';
import { SelectAirTicketRefundInvoice } from '../../Components/SelectAirTicketRefundInvoice';
import { SelectTicketByClientID } from '../../Components/SelectTicketByClientId';
import {
  IAirTicketInfoByNo,
  IAirTicketform_1,
} from '../../RefundTypes/RefundTypes';
import { AirTicketInfoUtils } from '../../Utils/AirTicket Utils/AirTicketCreateUtils/AirTicketInfoUtils';
import { ClientRefundChargeUtils } from '../../Utils/AirTicket Utils/AirTicketCreateUtils/ClientRefundChargeUtils';
import { VendorRefundChargeUtils } from '../../Utils/AirTicket Utils/AirTicketCreateUtils/VendorRefundChargeUtils';

type Props = { permission?: IPermission };

const AirticketRefund = ({ permission }: Props) => {
  const [clientId, setClientId] = useState<string>();
  const [date, setDate] = useState<string>();
  const [airTicketIsVisible_Ticket, setAirTicketIsVisible_Ticket] =
    useState<boolean>(true);
  const [airTicketIsVisible_charge, setAirTicketIsVisible_charge] =
    useState<boolean>(false);
  const [airTicketIsVisible_summary, setAirTicketIsVisible_summary] =
    useState<boolean>(false);
  const [airTicketIsVisible_clientRefund, setAirTicketIsVisible_clientRefund] =
    useState<boolean>(false);
  const [airTicketIsVisible_vendorRefund, setAirTicketIsVisible_vendorRefund] =
    useState<boolean>(false);
  const [clientRefundTotal, setClientRefundTotal] = useState<number>();
  const [clientRefundChargeTotal, setClientChargeRefundTotal] =
    useState<number>();
  const [tableData, setTableData] = useState<IAirTicketInfoByNo[]>([]);

  useEffect(() => {
    if (airTicketIsVisible_summary) {
      setClientRefundTotal(selling_price);
      setClientChargeRefundTotal(client_charge_temp);
    }
  }, [airTicketIsVisible_summary]);

  const [invoiceId, setInvoiceId] = useState<number>();

  const [form] = Form.useForm();
  const [tableForm] = Form.useForm();
  const [clientForm] = Form.useForm();
  const [changeColor, setChangeColor] = useState('');

  useEffect(() => {
    form.setFieldValue('ticket_no', undefined);
  }, [clientId, invoiceId]);

  useEffect(() => {
    form.setFieldValue('invoice_id', undefined);
  }, [clientId]);

  useEffect(() => {
    form.setFieldValue('date', dayjs());
  }, []);

  const [fetchClientDetails, { data: clientData, isLoading }] =
    useLazyGetSingleClientsQuery();

  useEffect(() => {
    const lastBalance =
      Number(
        clientData?.data?.client_lbalance
          ? clientData?.data?.client_lbalance
          : clientData?.data?.combine_lastbalance_amount
      ) || 0;

    const balance = Number(lastBalance) || 0;

    let color;
    if (balance > 0) {
      color = 'green';
    } else if (balance < 0) {
      color = 'red';
    } else {
      color = '';
    }

    setChangeColor(color);
  }, [clientData]);

  const [
    fetchTicketInfo,
    { data: ticketInfo, isLoading: isTicketInfoLoading, isUninitialized },
  ] = useLazySearchTicketNoQuery({
    selectFromResult: (cache: any) => {
      const data = cache.data?.data;
      const dataToReturn: IAirTicketInfoByNo[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          dataToReturn.push({
            ...element,
            key: i + 1,
            client_charge: 0,
            vrefund_charge_amount: 0,
          });
        }
      }
      return { ...cache, data: dataToReturn };
    },
  });

  useEffect(() => {
    setTableData(ticketInfo);
  }, [ticketInfo.length]);

  useEffect(() => {
    form.setFieldValue('ticket_ids', undefined);
  }, [clientId]);

  const onTableClientRefundChange = (
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setTableData((prevData) => {
      const newData = [...prevData];
      const newItem = { ...newData[index] };
      newItem.client_charge = Number(value);
      newData[index] = newItem;
      return newData;
    });
  };
  const onTableVendorRefundChange = (
    { target: { value } }: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setTableData((prevData) => {
      const newData = [...prevData];
      const newItem = { ...newData[index] };
      newItem.vrefund_charge_amount = Number(value);
      newData[index] = newItem;
      return newData;
    });
  };

  const onFinishTicketInfos = async (values: IAirTicketform_1) => {
    setDate(values.date);
    const body: IAirTicketform_1 = {
      ...values,
    };

    fetchClientDetails(clientId as string);
    fetchTicketInfo(body, true);
    setAirTicketIsVisible_Ticket(false);
    setAirTicketIsVisible_charge(true);
  };

  const onTableFormFinish = async () => {
    setAirTicketIsVisible_charge(false);
    setAirTicketIsVisible_summary(true);
  };

  let client_charge_temp = 0;
  let vendor_charge_temp = 0;
  let selling_price = 0;
  return (
    <div>
      <BreadCrumb arrOfOption={['Refund', 'Create AirTicket Refund']} />
      <Link to='/refund/history/airticket'>
        <Button type='primary' style={{ marginBottom: '1rem' }}>
          <ArrowLeftOutlined />
          Return to AirTicket Refund List
        </Button>
      </Link>

      {airTicketIsVisible_Ticket && (
        <Form layout='vertical' form={form} onFinish={onFinishTicketInfos}>
          <Card>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
              <SelectClients
                name='client_id'
                label='Select Client'
                required
                size={6}
                onChange={(e) => {
                  setClientId(e as string);
                }}
                offDropDown
              />

              <SelectAirTicketRefundInvoice
                name='invoice_id'
                label='Select Invoice'
                clientID={clientId as string}
                onChange={(e: any) => setInvoiceId(e)}
                size={6}
                required
              />
              <SelectTicketByClientID
                name='ticket_no'
                label='Select Ticket No.'
                clientID={clientId}
                invoiceId={invoiceId}
                size={6}
                mode='multiple'
                required
              />
              <DateInput label='Refund Date' name='date' size={3} />
            </Row>
            {permission?.[crud.read] && (
              <FormButton label='Submit' textAlign='left' />
            )}
          </Card>
        </Form>
      )}

      {airTicketIsVisible_charge && (
        <Form layout='vertical' form={tableForm} onFinish={onTableFormFinish}>
          <FormHeaderTitle title='AirTicket Information' />
          <Table
            bordered
            columns={AirTicketInfoUtils(
              onTableClientRefundChange,
              onTableVendorRefundChange
            )}
            dataSource={tableData}
            scroll={{ x: true }}
            loading={{
              spinning: isTicketInfoLoading,
              indicator: loadingIndicator,
            }}
            size='small'
            pagination={pagination(tableData.length)}
          />
          {permission?.[crud.read] && <FormButton label='Submit' />}
        </Form>
      )}

      {airTicketIsVisible_summary && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '50%', marginRight: '5px' }}>
              <FormHeaderTitle title='Summary of Client return charge :' />
              <Table
                bordered
                columns={ClientRefundChargeUtils()}
                dataSource={tableData}
                scroll={{ x: true }}
                loading={{
                  spinning: isTicketInfoLoading,
                  indicator: loadingIndicator,
                }}
                size='small'
                pagination={pagination(tableData?.length)}
                summary={(clientTableSummary) => {
                  clientTableSummary.forEach(
                    ({
                      client_charge,
                      airticket_client_price,
                      vrefund_charge_amount,
                    }) => {
                      client_charge_temp = client_charge + client_charge_temp;
                      vendor_charge_temp =
                        vrefund_charge_amount + vendor_charge_temp;
                      selling_price =
                        Number(airticket_client_price) + selling_price;
                    }
                  );

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                        {/* <Table.Summary.Cell index={2} /> */}
                        <Table.Summary.Cell index={2}>
                          <Typography.Text>
                            {selling_price || 0}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Typography.Text>
                            {client_charge_temp}
                          </Typography.Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </div>

            <div style={{ width: '50%' }}>
              <FormHeaderTitle title=' Summary of Vendor refund charge :' />
              <Table
                size='small'
                bordered
                columns={VendorRefundChargeUtils()}
                dataSource={tableData}
                scroll={{ x: true }}
                loading={{
                  spinning: isTicketInfoLoading,
                  indicator: loadingIndicator,
                }}
                pagination={pagination(tableData.length)}
                summary={(clientTableSummary) => {
                  let vendor_charge_temp = 0;
                  let purchase_price = 0;

                  clientTableSummary.forEach(
                    ({ vrefund_charge_amount, airticket_purchase_price }) => {
                      vendor_charge_temp =
                        vrefund_charge_amount + vendor_charge_temp;
                      purchase_price =
                        Number(airticket_purchase_price) + purchase_price;
                    }
                  );

                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                        {/* <Table.Summary.Cell index={2} /> */}
                        <Table.Summary.Cell index={2} />
                        <Table.Summary.Cell index={3}>
                          <Typography.Text>
                            {purchase_price || 0}
                          </Typography.Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={4}>
                          <Typography.Text>
                            {vendor_charge_temp}
                          </Typography.Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
            </div>
          </div>

          <Button
            type='primary'
            onClick={() => {
              setAirTicketIsVisible_clientRefund(true);
              setAirTicketIsVisible_summary(false);
            }}
            style={{ marginTop: '1rem' }}
            loading={isLoading}
          >
            Check And Confirm To Client Payment
          </Button>
        </>
      )}
      {airTicketIsVisible_clientRefund && (
        <>
          <FormHeaderTitle title='Client Refund Information :' />
          <Descriptions size='small' bordered column={1}>
            <DescriptionsItem label='Client Name'>
              {clientData?.data?.client_name
                ? clientData?.data?.client_name
                : clientData?.data?.combine_name}
            </DescriptionsItem>
            <DescriptionsItem label='Total Refund'>
              {clientRefundTotal}
            </DescriptionsItem>
            <DescriptionsItem label='Total Refund	Charge'>
              {clientRefundChargeTotal}
            </DescriptionsItem>
            <DescriptionsItem label='Total Return	Amount'>
              {(clientRefundTotal != undefined &&
                clientRefundChargeTotal != undefined &&
                clientRefundTotal - clientRefundChargeTotal) ||
                0}
            </DescriptionsItem>
            {tableData.length && (
              <DescriptionsItem label='Client Last Balance'>
                <span style={{ color: changeColor }}>
                  {Number(
                    clientData?.data?.client_lbalance
                      ? clientData?.data?.client_lbalance
                      : clientData?.data?.combine_lastbalance_amount
                  ) || 0}
                </span>
              </DescriptionsItem>
            )}
          </Descriptions>

          <AirticketPreviewForm
            form={clientForm}
            client_id={clientId as string}
            clientRefundTotal={clientRefundTotal}
            clientRefundChargeTotal={clientRefundChargeTotal}
            tableData={tableData}
            refund_date={date as string}
            invoiceId={invoiceId}
            setAirTicketIsVisible_vendorRefund={
              setAirTicketIsVisible_vendorRefund
            }
            airTicketIsVisible_vendorRefund={airTicketIsVisible_vendorRefund}
          />
        </>
      )}
    </div>
  );
};

export default AirticketRefund;
