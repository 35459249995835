import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space, Switch, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../auth/states/userSlice';
import { IPermission, crud } from '../../../common/types/commonTypes';
import { toasterNotification } from '../../../common/utils/ToasterNotification';
import assyncWrapper from '../../../common/utils/assyncWrapper';
import { getRowHightLightClass } from '../../../common/utils/rowColorChange';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import {
  useDeleteVendorMutation,
  useLazyGetVendorDetailsQuery,
  useUpdateVendorStatusMutation,
} from '../api/endpoints/vendorEndpoints';
import { IAllVendors, IVendorStatus } from '../types/vendor.interfaces';
import ViewsSingleVendor from './ViewsSingleVendor';

type Props = {
  listOfVendors?: IAllVendors[] | undefined;
  permission?: IPermission;
  refetch: ({ query }: { query?: string | undefined }) => void;
  pagination: {
    current: number;
    pageSize: number;
  };
  handlePaginationChange: (current: number, pageSize: number) => void;
  count?: number;
};

const ListOfVendorsTable = ({
  listOfVendors,
  permission,
  pagination,
  handlePaginationChange,
  count,
}: Props) => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const [update, { isLoading }] = useUpdateVendorStatusMutation();
  const [deleteVendor, { isError, isSuccess, isLoading: deleteLoading }] =
    useDeleteVendorMutation();
  const user = useSelector(selectUser);

  const [
    fetchVendorByID,
    {
      data: singleVendor,
      isSuccess: vendorSuccess,
      isLoading: fetchLoading,
      isFetching,
    },
  ] = useLazyGetVendorDetailsQuery();

  const [selectForStatusChangeId, setSelectForStatusChangeId] =
    useState<number>();
  const handleChangeStatus = async (id: number, status: number) => {
    setSelectForStatusChangeId(id);
    await assyncWrapper(async () => {
      const statusCreds: IVendorStatus = {
        id,
        status,
        updated_by: user?.user_id as number,
      };
      await update(statusCreds);
    });
  };
  // ===========handle vendor delete================
  const handleVendorDelete = async (id: number, index: number) => {
    setColumnIndex(index);
    deleteVendor({ id: id, vendor_deleted_by: user?.user_id });
  };

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      toasterNotification('success', 'Vendor has been deleted!');
    }
    if (isError) {
      setColumnIndex(null);
      toasterNotification('error', 'Vendor can not delete now!');
    }
  }, [isSuccess, isError]);

  //==========================Vendor Details Modal=====================
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = async (vendor_id: number) => {
    setIsModalOpen(true);
    await fetchVendorByID(vendor_id);
  };

  // ===============columns=======================
  const columns: ColumnsType<IAllVendors> = [
    {
      title: 'SL',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'vendor_name',
      key: 'vendor_id',
      render: (_, data) => (
        <Link to={`/reports/vendor_ledger?vendor_id=${data.vendor_id}`}>
          {data.vendor_name}
        </Link>
      ),
    },
    {
      title: 'Mobile',
      dataIndex: 'vendor_mobile',
      key: 'vendor_id',
    },
    {
      title: 'Email',
      dataIndex: 'vendor_email',
      key: 'vendor_id',
    },

    {
      title: 'Present Balance',
      dataIndex: 'vendor_lbalance',
      sorter: (a, b) => a.vendor_lbalance - b.vendor_lbalance,
      key: 'vendor_id',

      render: (amount) => {
        return (
          <>
            <div>
              {amount > 0
                ? 'Advance : '
                : amount < 0
                ? 'Due : '
                : amount == 0 && 'Balanced : '}
              <span
                style={{
                  color:
                    Number(amount) > 0
                      ? 'green'
                      : Number(amount) < 0
                      ? 'red'
                      : Number(amount) == 0
                      ? ''
                      : '',
                }}
              >
                {Math.abs(amount)}
              </span>
            </div>
          </>
        );
      },
    },
    {
      title: 'Fixed Balance',
      dataIndex: 'vendor_fixed_advance',
      key: 'vendor_fixed_advance',
      align: 'center',
      render: (_, record) => Number(record.vendor_fixed_advance),
    },
    {
      title: 'Status',
      dataIndex: 'vendor_activity_status',
      key: 'vendor_id',
      align: 'center',

      render: (status, data) => (
        <>
          {permission?.[crud.update] && (
            <Popconfirm
              title={`${
                data.vendor_activity_status
                  ? 'Sure to Inactive?'
                  : 'Sure to active?'
              }`}
              onConfirm={() =>
                handleChangeStatus(
                  data?.vendor_id,
                  data?.vendor_activity_status ? 0 : 1
                )
              }
            >
              <Switch
                loading={
                  selectForStatusChangeId === data?.vendor_id && isLoading
                }
                style={{
                  backgroundColor: data?.vendor_activity_status
                    ? 'green'
                    : '#ff4d4f',
                }}
                checkedChildren='Active'
                unCheckedChildren='Inactive'
                checked={data?.vendor_activity_status ? true : false}
              />
            </Popconfirm>
          )}
        </>
      ),
    },

    {
      title: 'Action',
      render: (_, data, index) => (
        <Space>
          {permission?.[crud.update] && (
            <Link
              to={`/vendors/payment/add/${'vendor-' + data.vendor_id}`}
              state={location.pathname}
            >
              <Button size='small' type='primary'>
                <PlusOutlined /> Add Payment
              </Button>
            </Link>
          )}
          <>
            {permission?.['read:any'] && (
              <Button
                size='small'
                type='primary'
                onClick={() => showModal(data.vendor_id)}
              >
                View
              </Button>
            )}
          </>

          {permission?.[crud.update] && (
            <Link
              to={`/vendors/edit/${data.vendor_id}`}
              state={location.pathname}
            >
              <Button size='small' type='primary'>
                <EditOutlined /> Edit
              </Button>
            </Link>
          )}

          {permission?.[crud.delete] && data.trxn_count === 0 && (
            <Button
              size='small'
              danger
              type='primary'
              loading={columnIndex === index && true}
            >
              <Popconfirm
                title='Sure to delete?'
                onConfirm={() => handleVendorDelete(data.vendor_id, index)}
              >
                Delete
              </Popconfirm>
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <ViewsSingleVendor
        singleVendor={singleVendor}
        isFetching={isFetching}
        fetchLoading={fetchLoading}
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
      />

      <Table
        size='small'
        rowClassName={(item) => getRowHightLightClass(item.vendor_created_date)}
        bordered
        rowKey={({ vendor_id }) => vendor_id + 1}
        columns={columns}
        dataSource={listOfVendors}
        scroll={{ x: true }}
        pagination={
          count !== undefined && count < 20
            ? false
            : {
                ...pagination,
                total: count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
      />

      {isLoading && <LoadingIndicator />}
    </>
  );
};

export default ListOfVendorsTable;
