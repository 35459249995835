import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Popconfirm,
  Row,
  Space,
  Table,
  Typography,
  message,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../auth/states/userSlice';
import { crud, perProps } from '../../../common/types/commonTypes';
import { FormInputItem } from '../../../components/common/FormItem/FormItems';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import {
  useDeleteVendorPaymentMutation,
  useGetAllVendorPaymentQuery,
} from '../api/endpoints/vendorPaymentEndpoints';
import LoadingIndicator from '../../../components/common/spinner/LoadingIndicator';
import { IVendorPaymentList } from '../types/vendor.interfaces';

const ListOfVendPayment = ({ permission }: perProps) => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  // Get Data with Pagination and Search
  const [search, setSearch] = useState('');

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const {
    data: allVendors,
    isLoading,
    refetch,
  } = useGetAllVendorPaymentQuery({ ...pagination, search });
  const user = useSelector(selectUser);
  const [
    deleteVendorPay,
    { isError: deleteError, isSuccess: deleteSucc, isLoading: deleteLoading },
  ] = useDeleteVendorPaymentMutation();

  useEffect(() => {
    if (deleteError) {
      message.error('Thare was an error please try agein');
    }
  }, [deleteLoading]);

  // ====== vendor payment delete handler
  const deleteVendorPayment = (id: number, index: number) => {
    const body = { id, updated_by: user?.user_id as number };
    setColumnIndex(index);
    deleteVendorPay(body);
  };

  // ================== notification =========================

  useEffect(() => {
    if (deleteSucc) {
      setColumnIndex(null);
      message.success('Vendor payment has been deleted!');
    }
    if (deleteError) {
      setColumnIndex(null);
      message.error('Thare was an error please try agein');
    }
  }, [deleteSucc, deleteError]);

  const columns: ColumnsType<IVendorPaymentList> = [
    {
      title: 'SL',
      key: 'key',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Date',
      key: 'payment_date',
      align: 'center',
      render: (_, e) => (
        <>{e.payment_date && dayjs(e.payment_date).format('DD-MM-YYYY')}</>
      ),
    },
    {
      title: 'Voucher No',
      dataIndex: 'vouchar_no',
      key: 'vouchar_no',
      align: 'center',
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      render: (_, data) => (
        <Link to={`/reports/vendor_ledger?vendor_id=${data.vpay_vendor_id}`}>
          {data.vendor_name}
        </Link>
      ),
    },
    {
      title: 'Account',
      dataIndex: 'account_name',
      key: 'account_name',
    },
    {
      title: 'Total Payment Amount',
      dataIndex: 'payment_amount',
      key: 'payment_amount',
      align: 'center',
      render: (_, e) => <>{Math.abs(e.payment_amount)}</>,
    },
    {
      title: 'Action',
      key: 'operation',
      width: 125,
      render: (_, data, index) => (
        <Space size='small'>
          {permission?.['read:any'] && (
            <Link
              to={`/vendors/payment/${data.vpay_id}`}
              state={location.pathname}
            >
              <Button
                size='small'
                type='primary'
                style={{ backgroundColor: '#00a65a', border: 'none' }}
              >
                <Typography style={{ color: 'white' }}>View</Typography>
              </Button>
            </Link>
          )}
          {permission?.[crud.update] && (
            <Button
              size='small'
              type='primary'
              style={{ background: '#00b4e9', border: 'none' }}
            >
              <Link
                to={`/vendors/payment/edit/${data.vpay_id}`}
                state={location.pathname}
              >
                <Typography style={{ color: 'white' }}>Edit</Typography>
              </Link>
            </Button>
          )}
          {permission?.[crud.delete] && (
            <Button
              size='small'
              type='primary'
              style={{ background: '#e90800', border: 'none' }}
              loading={columnIndex === index && true}
            >
              <Popconfirm
                onConfirm={() => deleteVendorPayment(data.vpay_id, index)}
                title='Sure to delete?'
              >
                Delete
              </Popconfirm>
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const handleSearch = (e: any) => {
    const search = e.target.value;
    setSearch(search);
  };

  return (
    <>
      <BreadCrumb
        arrOfOption={['List Of Vendor Payments']}
        refetch={refetch}
        reloaderSize='small'
      />

      <Row justify={'space-between'}>
        <Col lg={4}>
          {permission?.['create:any'] && (
            <Link to='/vendors/payment/add' state={location.pathname}>
              <Button type='primary' icon={<PlusOutlined />}>
                Add Payment
              </Button>
            </Link>
          )}
        </Col>

        <FormInputItem
          onBlur={handleSearch}
          onPressEnter={handleSearch}
          placeholder='🔍 Search by combine'
          size={6}
        />
      </Row>

      <Table
        bordered
        size='small'
        rowKey={(e) => e.vpay_id}
        columns={columns}
        dataSource={allVendors?.data}
        scroll={{ x: true }}
        pagination={
          allVendors?.count !== undefined && allVendors?.count < 20
            ? false
            : {
                ...pagination,
                total: allVendors?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
      />
      {(isLoading || deleteLoading) && <LoadingIndicator />}
    </>
  );
};

export default ListOfVendPayment;
