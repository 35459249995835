import { message } from 'antd';
import { useEffect, useState } from 'react';
import { IAllInvoices } from '../../../../common/types/commonInterfaces';
import {
  useDeleteAirticketMutation,
  useGetAllInvoicesQuery,
} from '../../api/endpoints/airticketInvoiceEndpoints';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../auth/states/userSlice';
import { IPermission } from '../../../../common/types/commonTypes';
import InvoiceListHeader from '../../../../components/common/Invoice/InvoiceListHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';

type Props = {
  permission?: IPermission;
  addMRPermission: ['*'] | undefined;
};

const ListOfInvoiceAirticket = ({ permission, addMRPermission }: Props) => {
  const [dataSource, setDataSource] = useState<IAllInvoices[]>();
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const [count, setCount] = useState<number>(0);
  const [isTrash, setIsTrash] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const user = useSelector(selectUser);

  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');

  // LINKS
  const createLink = '/airticket/add';
  const viewLink = '/airticket/details';
  const editLink = '/airticket/edit';

  // GET ALL DATA ,TRASH, DELETE AND RESTORE
  const {
    data,
    isFetching,
    refetch: getAllRefetch,
    isLoading: dataIsLoading,
  } = useGetAllInvoicesQuery(
    query +
      `&from_date=${date[0] || ''}&to_date=${date[1] || ''}&search=${search}`
  );

  const allData = data?.data;
  useEffect(() => {
    setDataSource(allData);
    setCount(data?.count || 0);
  }, [allData]);

  const [deleteInvoice, { isError, isSuccess, isLoading, error }] =
    useDeleteAirticketMutation();

  // DELETE HANDLER
  const handleOnDelete = (id: number, index: number) => {
    setColumnIndex(index);
    deleteInvoice({
      id,
      user_id: user?.user_id,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success(
        !isTrash
          ? 'Invoice Deleted Successfully!'
          : 'Invoice Restored Successfully!'
      );
    }
    if (isError) {
      setColumnIndex(null);
      message.error('Thare was an error');
    }
  }, [isError, isSuccess]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    getAllRefetch();
  };

  return (
    <>
      <BreadCrumb arrOfOption={['Invoice', 'Air Ticket']} />

      <InvoiceListHeader
        InvocieModalTitle={'Invoice Air Ticket'}
        data={data?.data}
        setIsTrash={setIsTrash}
        editLink={editLink}
        createLink={createLink}
        handleOnDelete={handleOnDelete}
        isTrash={isTrash}
        permission={permission}
        viewLink={viewLink}
        setDataSource={setDataSource}
        dataSource={dataSource}
        count={count}
        setCount={setCount}
        setQuery={setQuery}
        isLoading={dataIsLoading || isFetching}
        deleteLoading={isLoading}
        addMRPermission={addMRPermission}
        refetch={refetch}
        setDate={setDate}
        setSearch={setSearch}
        columnIndex={columnIndex}
      />
    </>
  );
};

export default ListOfInvoiceAirticket;
