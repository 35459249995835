import { ArrowLeftOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  ConfigProvider,
  Divider,
  Image,
  Row,
  Space,
  Table,
  Typography,
  theme,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useAppSelector } from '../../../../app/hooks';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { PhoneParser } from '../../../../helpers/PhoneFormatter';
import { a4sizeStyle } from '../../../Loan_Management/Pages/Loan/ViewLoan';
import { useGetSingleOthersRefundClientQuery } from '../../Api/Endpoints/OthersRefundClientEndpoints';
import { OthersRefundClientSingleViewUtils } from '../../Utils/OthersClientUtils/OthersClientSingleViewUtils/OthersRefundClientSingleViewUtils';
import NumToWord from '../../../../components/number_to_words/NumToWord';

type Props = {};

const OthersRefundClientView = (props: Props) => {
  const { id } = useParams();
  const orgInfo = useAppSelector((state) => state.user?.organization_info);
  const { data: singleOthersRefund, isLoading } =
    useGetSingleOthersRefundClientQuery(Number(id));

  const [client_total_refund_amount, setClient_total_refund_amount] =
    useState<number>();

  let client_total_refund_price = 0;
  let client_total_refund_charge = 0;

  singleOthersRefund?.data &&
    singleOthersRefund?.data?.vendor_refund_info.forEach((item) => {
      client_total_refund_price =
        client_total_refund_price +
        Number(item?.vrefund_client_refund_amount || 0);

      client_total_refund_charge =
        client_total_refund_charge +
        Number(item?.vrefund_client_refund_charge || 0);
    });

  useEffect(() => {
    if (singleOthersRefund?.data) {
      setClient_total_refund_amount(
        client_total_refund_price - client_total_refund_charge
      );
    }
  }, [singleOthersRefund?.data]);

  const componentRefClient = useRef(null);
  const refs: React.MutableRefObject<HTMLDivElement[]> = useRef([]);

  const handleClientPrint = useReactToPrint({
    content: () => componentRefClient.current,
    documentTitle: `OthersRefundClient`,
  });

  return (
    <div>
      <BreadCrumb arrOfOption={['Refund', 'View Others Refund Voucher']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Link to='/refund/history/others' state={location.pathname}>
          <Button type='primary'>
            <ArrowLeftOutlined />
            Return to Others Refund List
          </Button>
        </Link>
      </Space>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Card
            style={a4sizeStyle}
            title='Client Copy'
            extra={
              <Button type='primary' onClick={handleClientPrint}>
                <PrinterOutlined />
                Print
              </Button>
            }
          >
            <div ref={componentRefClient} style={{ margin: '0.5in' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Image
                    style={{ marginBottom: '1rem' }}
                    width={100}
                    preview={false}
                    src={
                      orgInfo?.org_logo ||
                      'https://basis.org.bd/public/images/logo/54e198aa3eae15c215688af3bb13de5f16022022025439.png'
                    }
                    alt={orgInfo?.org_name || 'M360 ICT'}
                  />
                  <Typography style={{ fontWeight: '700' }}>
                    {orgInfo?.org_name || 'M360 ICT'}
                  </Typography>
                </div>

                <div>
                  <Typography.Title level={5}>REFUND VOUCHER</Typography.Title>
                  <Typography.Text strong>{`Refund Voucher No : ${
                    singleOthersRefund?.data &&
                    singleOthersRefund?.data.client_refund_info
                      ?.crefund_vouchar_number
                  }`}</Typography.Text>
                  <br />
                  <Typography.Text strong>{`Refund Date : ${
                    singleOthersRefund?.data?.client_refund_info
                      ?.crefund_date &&
                    dayjs(
                      singleOthersRefund?.data?.client_refund_info?.crefund_date
                    ).format('DD MMM YYYY')
                  }`}</Typography.Text>
                </div>
              </div>
              <div style={{ marginTop: '30px' }}>
                <Typography.Paragraph>
                  <br />
                  <Typography.Text strong>
                    {` Refund To : 
            ${
              singleOthersRefund?.data &&
              singleOthersRefund?.data.client_refund_info?.crefund_client_name
            }`}
                  </Typography.Text>
                  <br />
                  <Typography.Text strong>
                    {singleOthersRefund?.data &&
                    singleOthersRefund?.data?.client_refund_info
                      ?.crefund_client_mobile ? (
                      `Phone No: ${PhoneParser(
                        singleOthersRefund?.data?.client_refund_info
                          ?.crefund_client_mobile
                      )}`
                    ) : (
                      <></>
                    )}
                  </Typography.Text>
                </Typography.Paragraph>
              </div>
              <Table
                size='small'
                bordered
                columns={OthersRefundClientSingleViewUtils('client')}
                className='invoiceBillingTable'
                rowClassName={'invoiceBillingTd'}
                dataSource={singleOthersRefund?.data?.vendor_refund_info}
                loading={{ spinning: isLoading, indicator: loadingIndicator }}
                style={{ marginTop: '1rem', marginBottom: '1rem' }}
                rowKey={(record) => record.vrefund_id}
                pagination={false}
                summary={(record) => {
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={2} align='right'>
                          <Typography
                            style={{ textAlign: 'right', fontWeight: 'bold' }}
                          >
                            Total:
                          </Typography>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2}>
                          <Typography
                            style={{ textAlign: 'right', fontWeight: 'bold' }}
                          >
                            {client_total_refund_charge}
                          </Typography>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Typography
                            style={{
                              textAlign: 'right',
                              fontWeight: 'bold',
                            }}
                          >
                            {client_total_refund_price}
                          </Typography>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>

                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={2}>
                          <Typography
                            style={{ textAlign: 'right', fontWeight: 'bold' }}
                          >
                            Total Refund Amount:
                          </Typography>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2}>
                          <Typography style={{ textAlign: 'right' }}>
                            ({client_total_refund_price} -{' '}
                            {client_total_refund_charge})
                          </Typography>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3}>
                          <Typography
                            style={{ textAlign: 'right', fontWeight: 'bold' }}
                          >
                            {client_total_refund_price -
                              client_total_refund_charge}
                          </Typography>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  );
                }}
              />
              {Number(
                singleOthersRefund?.data?.client_refund_info
                  ?.crefund_return_amount || 0
              ) ? (
                <Typography.Text strong style={{ textAlign: 'right' }}>
                  <NumToWord
                    number={Number(
                      singleOthersRefund?.data?.client_refund_info
                        ?.crefund_return_amount || 0
                    )}
                  />
                </Typography.Text>
              ) : null}
              <Row></Row>
              <br />
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  margin: '20px 0px',
                }}
              >
                <div style={{ width: '220px' }}>
                  <Divider orientation='left' plain>
                    Customer
                  </Divider>
                </div>
                <div style={{ width: '220px' }}>
                  <Divider orientation='left' plain>
                    Authorised
                  </Divider>
                </div>
              </Row>
            </div>
          </Card>
        </div>

        {/* Vendor Recipient */}

        {singleOthersRefund?.data?.vendor_refund_info.map((item, index) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '40px',
            }}
          >
            <Card
              style={a4sizeStyle}
              title='Vendor Copy'
              extra={
                <Button type='primary' onClick={handleClientPrint}>
                  <PrinterOutlined />
                  Print
                </Button>
              }
            >
              <div ref={componentRefClient} style={{ margin: '0.5in' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <Image
                      style={{ marginBottom: '1rem' }}
                      width={100}
                      preview={false}
                      src={
                        orgInfo?.org_logo ||
                        'https://basis.org.bd/public/images/logo/54e198aa3eae15c215688af3bb13de5f16022022025439.png'
                      }
                      alt={orgInfo?.org_name || 'M360 ICT'}
                    />
                    <Typography style={{ fontWeight: '700' }}>
                      {orgInfo?.org_name || 'M360 ICT'}
                    </Typography>
                  </div>

                  <div>
                    <Typography.Title level={5}>
                      REFUND VOUCHER
                    </Typography.Title>
                    <Typography.Text strong>{`Refund Voucher No : ${
                      singleOthersRefund?.data &&
                      singleOthersRefund?.data.client_refund_info
                        ?.crefund_vouchar_number
                    }`}</Typography.Text>
                    <br />
                    <Typography.Text strong>{`Refund Date : ${
                      singleOthersRefund?.data?.client_refund_info
                        ?.crefund_date &&
                      dayjs(
                        singleOthersRefund?.data?.client_refund_info
                          ?.crefund_date
                      ).format('DD MMM YYYY')
                    }`}</Typography.Text>
                  </div>
                </div>
                <div style={{ marginTop: '30px' }}>
                  <Typography.Paragraph>
                    <br />
                    <Typography.Text strong>
                      {` Refund To : 
            ${
              singleOthersRefund?.data &&
              singleOthersRefund?.data.client_refund_info?.crefund_client_name
            }`}
                    </Typography.Text>
                    <br />
                    <Typography.Text strong>
                      {singleOthersRefund?.data &&
                      singleOthersRefund?.data?.client_refund_info
                        ?.crefund_client_mobile ? (
                        `Phone No: ${PhoneParser(
                          singleOthersRefund?.data?.client_refund_info
                            ?.crefund_client_mobile
                        )}`
                      ) : (
                        <></>
                      )}
                    </Typography.Text>
                  </Typography.Paragraph>
                </div>
                <Table
                  size='small'
                  bordered
                  columns={OthersRefundClientSingleViewUtils('vendor')}
                  dataSource={singleOthersRefund?.data?.vendor_refund_info}
                  loading={{ spinning: isLoading, indicator: loadingIndicator }}
                  className='invoiceBillingTable'
                  rowClassName={'invoiceBillingTd'}
                  style={{ marginTop: '1rem', marginBottom: '1rem' }}
                  rowKey={(record) => record.vrefund_id}
                  pagination={false}
                  summary={(record: any) => {
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={2}>
                            <Typography
                              style={{ textAlign: 'right', fontWeight: 'bold' }}
                            >
                              Total Refund Amount:
                            </Typography>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={2}>
                            <Typography style={{ textAlign: 'right' }}>
                              ({Number(record[index].vrefund_amount) || 0} -{' '}
                              {Number(record[index].vrefund_charge) || 0})
                            </Typography>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={3}>
                            <Typography
                              style={{ textAlign: 'right', fontWeight: 'bold' }}
                            >
                              {(Number(record[index].vrefund_amount) || 0) -
                                (Number(record[index].vrefund_charge) || 0)}
                            </Typography>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />
                {Number(
                  singleOthersRefund?.data?.client_refund_info
                    .crefund_return_amount
                ) ? (
                  <Typography.Text strong style={{ textAlign: 'right' }}>
                    {`${
                      item.vrefund_payment_type == 'MONEY_RETURN'
                        ? 'Money Return Amount: '
                        : 'Balance Adjustment Amount: '
                    } ${(
                      <NumToWord
                        number={Number(item.vrefund_return_amount || 0)}
                      />
                    )}
                    
                    `}
                  </Typography.Text>
                ) : null}
                <Row></Row>
                <br />
                <Row
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '20px 0px',
                  }}
                >
                  <div style={{ width: '220px' }}>
                    <Divider orientation='left' plain>
                      Customer
                    </Divider>
                  </div>
                  <div style={{ width: '220px' }}>
                    <Divider orientation='left' plain>
                      Authorised
                    </Divider>
                  </div>
                </Row>
              </div>
            </Card>
          </div>
        ))}
      </ConfigProvider>
    </div>
  );
};

export default OthersRefundClientView;
